import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ErrorBoundary} from 'react-error-boundary';

import {Store} from './store';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import ResultByID from './components/footprint/ResultByID';
import ErrorComponent from './components/ErrorComponent';

const router = createBrowserRouter([
    {
        path: '/',
        element: <ErrorBoundary fallback={<ErrorComponent/>}><App/></ErrorBoundary>,
    },
    {
        path: 'results/:id',
        element: <ErrorBoundary fallback={<ErrorComponent/>}><ResultByID/></ErrorBoundary>,
    },
]);

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(<Store><RouterProvider router={router}/></Store>);