import React from 'react';

function ErrorComponent() {
    return <div style={style}>
        <h4>Something went wrong!</h4>
        <p>Please contact us at: <a href="mailto:">kontakt@formue.se</a></p>
    </div>;
}


const style = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};

export default ErrorComponent;